import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { joinWaitlistThunk } from "../../store/thunks/dataThunks"; // Adjust the import path as needed

const WaitlistEmail = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      try {
        const response = await dispatch(joinWaitlistThunk(email)).unwrap(); // Use the joinWaitlist thunk
        setSubmitted(true);
        setEmail("");
        setIsValid(true);
        setError(null); // Clear any existing error
        console.log(response.message); // Log the response message
      } catch (error) {
        console.error(error);
        // Check if the error has a message and set it to the error state
        setError(
          error.message || "Failed to submit email. Please try again later."
        );
      }
    } else {
      setIsValid(false);
      setError("Please enter a valid email address.");
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <motion.div className="text-center">
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto mt-10 items-center gap-x-6">
        <div className="flex">
          <input
            type="email"
            placeholder="Join the waitlist"
            className="flex-grow px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#E5B13A] bg-white text-gray-900 border border-gray-300 rounded-none"
            value={email} // Bind the input value to the email state
            onChange={(e) => {
              setEmail(e.target.value); // Update the email state on change
              setIsValid(true); // Reset validity when the user starts typing
              setSubmitted(false); // Reset the submission state when the user starts typing
              setError(null); // Reset error state
            }}
            required
          />
          <motion.button
            type="submit"
            className="bg-[#E5B13A] text-[#0A2342] px-6 py-2 hover:bg-[#C99A2E] transition-colors flex items-center transform hover:scale-105 rounded-none"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}>
            Join <ArrowRightIcon className="h-5 w-5 ml-2" />
          </motion.button>
        </div>
      </form>
      {error && (
        <p className="mt-4 text-sm font-semibold text-red-600">{error}</p>
      )}

      {submitted && (
        <p className="mt-4 text-sm font-semibold text-[#0A2342]">
          Thank you! Your email has been submitted.
        </p>
      )}
    </motion.div>
  );
};

export default WaitlistEmail;
