import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-scroll";
import logo from "../../assets/Logo No Text.png";

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const navigation = [
    { name: "About Us", to: "about-us" },
    { name: "Platform", to: "homebuyer" },
    { name: "Team", to: "mission" },
    { name: "Waitlist", to: "cta" },
  ];

  const handleLoginClick = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  return (
    <header className="fixed w-full bg-white shadow-md z-50">
      <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <a href="#top" className="flex items-center scroll-smooth">
            <img src={logo} alt="Synergy AI Logo" width={40} height={40} />
            <span className="text-xl md:text-2xl font-bold text-[#0A2342] ml-2">
              Synergy AI
            </span>
          </a>
        </div>
        <div className="hidden md:flex space-x-8">
          <a
            href="#about"
            className="text-[#0A2342] hover:text-[#E5B13A] transition-colors">
            About
          </a>
          <a
            href="#features"
            className="text-[#0A2342] hover:text-[#E5B13A] transition-colors">
            Features
          </a>
          <a
            href="#team"
            className="text-[#0A2342] hover:text-[#E5B13A] transition-colors">
            Team
          </a>
          <a
            href="#contact"
            className="text-[#0A2342] hover:text-[#E5B13A] transition-colors">
            Contact
          </a>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => navigate("/login")}
            className="bg-[#0A2342] text-white px-4 py-2 hover:bg-[#1C4B82] transition-colors transform hover:scale-105">
            Login
          </button>
          <button
            onClick={() => navigate("/register")}
            className="bg-[#0A2342] text-white px-4 py-2 hover:bg-[#1C4B82] transition-colors transform hover:scale-105">
            Register
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
